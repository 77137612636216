"use strict";

const { first } = require("lodash");

(function ($) {

    //#region Navigation
    

    $('.withchild .icon').on('click', function (e) {
        e.preventDefault();
        $(this).closest('.nav-item').children('.sub-menu').first().toggleClass('show');
        $(this).find('i').toggleClass('fa-angle-right').toggleClass('fa-angle-down');
    });

    $('#sidebarToggle').on('click', function () {
        $('#accordionSidebar').toggleClass('show');
    });
    //#endregion

    //#region Scroll to top button appear
    $(document).scroll(function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
            $('.scroll-to-top').fadeIn();
        } else {
            $('.scroll-to-top').fadeOut();
        }
    });
    //#endregion

    //#region Smooth scrolling using jQuery easing
    $(document).on('click', 'a.scroll-to-top', function (e) {
        let $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top)
        }, 1000);
        e.preventDefault();
    });
    //#endregion

    //#region TOASTR
    toastr.options = {
        closeButton: true,
        positionClass: "toast-bottom-right",
        //extendedTimeOut: 0,
        //timeOut: 0,
    }

    window.myToast = function (typ, msg, status = undefined) {
        switch (typ) {
            case 'success': toastr.success(msg, status || 'Erfolg'); break;
            case 'info': toastr.info(msg, status || 'Information'); break;
            case 'warning': toastr.warning(msg, status || 'Warnung'); break;
            case 'error': toastr.error(msg, status || 'Fehler'); break;
        }
    }
    //#endregion

    $('document').ready(function () {
        

        //#region  Active/Inactiv Checkbox
        $('.ajaxcheckbox').off().on('change', function (e) {

            let active = ($(this).is(':checked')) ? 1 : 0;
            let data = {
                active: active,
                _token: $('meta[name="csrf-token"]').attr('content')
            };
            $.ajax({
                url: $(this).data('url'),
                method: 'put',
                data: data,
                success: function (response) {
                    if (response.status == 200) {
                        myToast('success', response.msg);
                    }
                    else {
                        myToast('error', response.status + ': ' + response.msg);
                    }
                },
                error: function (response) {
                    myToast('error', 'Daten konnten nicht geändert werden');
                }
            });
        });
        //#endregion

        //#region Menue

        let insertMenue = $('.card.menue .card-body ul').first();
        let nestableItem = $('#menu1');
        $('.card.menue .dd').nestable();

        //Select Menue left side
        $('.auswahl').on('click', 'h3', function () {
            $('.auswahl .card.card-body > div').addClass('collapse');
            $(this).next('div').removeClass('collapse');
        });

        //Select Menue right side
        $('.card.menue').on('click', function (e) {
            $('.card.menue .card-body').addClass('collapse');
            $(this).find('.card-body').removeClass('collapse');
            insertMenue = $(this).find('.card-body ul').first();
            nestableItem = $(this).find('.dd').first();
        });

        //template from left side to right side
        let newItem = function (id, type, label, url, openwindow) {
            let ulLiTemp = document.querySelector('#liDrag li');
            let ulLiTempContent = document.querySelector('#liDrag li .dd3-content span');
            let ulLiTempInpLabel = document.querySelector('#liDrag li .navigation_label');
            let ulLiTempInpUrl = document.querySelector('#liDrag li .navigation_url');
            let ulLiTempInpWin = document.querySelector('#liDrag li .individuel_openwindow');

            ulLiTemp.dataset.id = id;
            ulLiTemp.dataset.type = type;
            ulLiTemp.dataset.label = label;
            ulLiTemp.dataset.url = url;
            ulLiTemp.dataset.openwindow =  openwindow ? '1' : '0';

            ulLiTempContent.innerHTML = label;
            ulLiTempInpLabel.value = label;
            ulLiTempInpUrl.value = url;
            ulLiTempInpUrl.readOnly = (type != 'individuell') ?  true : false;
            ulLiTempInpWin.checked = openwindow ? true : false;

            return ulLiTemp.cloneNode(true);
        }

        //Left side categories
        $('.card.auswahl .categories li').off().on('click', function () {
            console.log(insertMenue);
            let auswahlNewLi = newItem($(this).data('id'), $(this).data('type'), $(this).text(), $(this).data('url'), false);
            insertMenue.append(auswahlNewLi);
            insertMenue.next('button.menue-store').removeClass('hide');
        });

        //Left side post
        $('.card.auswahl #post_btn').off().on('click', function () {
            let inpPost = document.querySelector('#post_search').dataset;
            let auswahlNewLi = newItem(inpPost.id, inpPost.type, inpPost.label,inpPost.url,false);
            insertMenue.append(auswahlNewLi);
            insertMenue.next('button.menue-store').removeClass('hide');
            $('.card.auswahl #post_btn').addClass('collapse');
        });

        //Left individueller Link
        $('#individuel_btn').off().on('click', function () {
            let label = $('#individuel_label');
            let url = $('#individuel_url');
            let openwin = $('#individuel_openwindow');
            if (label.val().length == 0 || url.val().length == 0) {
                myToast('error', 'Link Text und Adresse sind Pflichtfelder!');
                return;
            }
            let auswahlNewLi = newItem('', 'individuell', label.val(), url.val(), openwin.is(":checked") ? true : false);

            label.val('');
            url.val('');
            openwin.prop('checked', false);

            insertMenue.append(auswahlNewLi);
            insertMenue.next('button.menue-store').removeClass('hide');
        });


        /* nesable */
        //open/close
        $("body").on("click", ".item-edit, .item-close", function (e) {
            e.preventDefault();
            var item_setting = $(this).closest(".dd-item").find(".item-settings").first();
            if (item_setting.hasClass("d-none")) {
                item_setting.removeClass("d-none");
            } else {
                item_setting.addClass("d-none");
            }
        });

        //delete
        $("body").on("click", ".item-delete", function (e) {
            e.preventDefault();
            $(this).closest("li").remove();
        });
      

        //change label
        $("body").on('keyup change', '.item-settings .navigation_label', function () {
            let closest = $(this).closest('li');
            closest.attr('data-label', this.value);
            closest.children('.dd3-content').find('span').html(this.value);
        });
        //change url
        $("body").on('keyup change', '.item-settings .navigation_url', function () {
            let closest = $(this).closest('li');
            closest.attr('data-url', this.value);
        });
        //change openWindow
        $("body").on('change', '.item-settings .individuel_openwindow', function () {
            let closest = $(this).closest('li');
            closest.attr('data-openwindow', this.checked ? 1 : 0);
        });


        //generate navigation from UL List
        $('.menue-store').on('click', function (e) {
            e.preventDefault();
            const dataLi = [];

            const getMenues = function (e) {
                const data = {
                    id: e.dataset.id,
                    type: e.dataset.type,
                    label: e.dataset.label,
                    url: e.dataset.url,
                    openwindow: e.dataset.openwindow,
                    children: []
                }
                const findCildrenItem = [...e.children].find((item) => {
                    return item.nodeName === 'OL' || item.nodeName === 'UL';
                });
                if (findCildrenItem) {
                    [...findCildrenItem.children].forEach(getMenues, { insert: data.children });
                }
                this.insert.push(data);
            };
            [...insertMenue.children('li')].forEach(getMenues, { insert: dataLi });

            //Data for ajax

            let data = {
                menueid: this.dataset.menuid,
                menue: dataLi
            }
            $.ajax({
                url: this.dataset.action,
                method: "POST",
                headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
                data: data,
                success: function (response) {
                    if (response.status == 200) {
                        myToast('success', response.msg);
                    }
                    else {
                        myToast('error', response.msg, response.status);
                    }
                },
                error: function (xhr) {
                    console.log(xhr.status, xhr.statusText);
                }
            });

        });
        //#endregion

    });
}(jQuery));