try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('popper.js').default;
    window.toastr = require('toastr');
    require('bootstrap');
    require('./jquery.nestable');
    require('./cox');
} catch (e) {
    
}
